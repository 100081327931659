import { clsx } from 'clsx';
import { useContext, useRef } from 'react';
import { Overlay, mergeProps, useModalOverlay, usePress } from 'react-aria';

import { ModalContext } from '../modal.context';

import style from './style.module.scss';

import type IReactAria from 'react-aria';

interface IModalPortalProps extends IReactAria.AriaModalOverlayProps, ICustomizable {
  children: React.ReactNode;
  /** `divide?: boolean;` the modal will have a divider between its header, content and action sections */
  divide?: boolean;
  /** `expand?: boolean;` the modal will expand to fullscreen */
  expand?: boolean;
  onOverlayPress?: () => void;
  entrance?: 'start' | 'bottom' | 'center';
  invisible?: boolean;
}

export const ModalPortal = (props: IModalPortalProps) => {
  const ctx = useContext(ModalContext);

  const { modalProps, underlayProps } = useModalOverlay(props, ctx.state, ctx.modalRef);

  const underlayRef = useRef<HTMLDivElement>();
  const { isPressed: isUnderlayPressed, pressProps: underlayPressProps } = usePress({
    ref: underlayRef,
    onPress: () => {
      if (props.isDismissable) {
        ctx.state.close();
      }

      ctx.onOverlayPress?.();
      props.onOverlayPress?.();
    },
  });

  if (!ctx.state.isOpen) {
    return <></>;
  }

  return (
    <Overlay>
      <div className={style['modal-portal']} data-invisible={props.invisible}>
        <div
          {...mergeProps(underlayProps, underlayPressProps)}
          className={style['modal-portal__underlay']}
          data-transition={ctx.state.stage}
          aria-pressed={isUnderlayPressed}
          role={props.isDismissable ? 'button' : undefined}
          ref={underlayRef}
        />

        <div
          {...props}
          {...mergeProps(ctx.modalProps, modalProps)}
          className={clsx(style['modal-portal__container'], props.className)}
          data-divide={props.divide}
          data-expand={props.expand}
          data-transition={ctx.state.stage}
          data-entrance={props.entrance || 'center'}
          ref={ctx.modalRef}
        >
          {props.children}
        </div>
      </div>
    </Overlay>
  );
};
