//
// === CONSTANTS ===============================================================
export const MILLISECOND = 1e3 as const;
export const NEVER = 0 as const;

//
// === METHODS =================================================================
/**
 * Identifies a value as a duration in milliseconds.
 *
 * @param {number} value - The value that represents a duration in milliseconds.
 * @returns {number} The same value that represents a duration in milliseconds.
 */
export function milliseconds(value: number) {
  return value;
}

/**
 * Converts a duration in seconds to milliseconds.
 *
 * @param {number} value - The value that represents a duration in seconds.
 * @returns {number} The equivalent duration in milliseconds.
 */
export function seconds(value: number) {
  return value * MILLISECOND;
}

/**
 * Converts a duration in minutes to milliseconds.
 *
 * @param {number} value - The value that represents a duration in minutes.
 * @returns {number} The equivalent duration in milliseconds.
 */
export function minutes(value: number) {
  return value * 60 * MILLISECOND;
}

/**
 * Converts a duration in hours to milliseconds.
 *
 * @param {number} value - The value that represents a duration in hours.
 * @returns {number} The equivalent duration in milliseconds.
 */
export function hours(value: number) {
  return value * 60 * 60 * MILLISECOND;
}

/**
 * Converts a duration in days to milliseconds.
 *
 * @param {number} value - The value that represents a duration in days.
 * @returns {number} The equivalent duration in milliseconds.
 */
export function days(value: number) {
  return value * 60 * 60 * 24 * MILLISECOND;
}

/**
 * Converts a duration in weeks to milliseconds.
 *
 * @param {number} value - The value that represents a duration in weeks.
 * @returns {number} The equivalent duration in milliseconds.
 */
export function weeks(value: number) {
  return value * 60 * 60 * 24 * 7 * MILLISECOND;
}

/**
 * Returns the current timestamp in milliseconds.
 *
 * @returns {number} The current time in milliseconds since the Unix epoch.
 */
export function now() {
  return Date.now();
}

/**
 * Calculates a future timestamp by adding specified durations to the current time.
 *
 * @param {...number} to - An array of durations in milliseconds to add to the current time.
 * @returns {number} The resulting future time in milliseconds since the Unix epoch.
 */
export function due(...to: number[]) {
  return to.reduce((future, ms) => future + ms, Date.now());
}

/**
 * Converts a date-time to an ISO string.
 *
 * @param {number | string | Date} value - The date or timestamp to convert.
 * @returns {string} The ISO formatted string.
 */
export function toIso(value: number | string | Date) {
  return new Date(value).toISOString();
}

/**
 * Determines if the current time is prior to a specified date or timestamp.
 *
 * @param {number | string | Date} to - The date or timestamp to compare against the current time.
 * @returns {boolean} True if the current time is before the specified date or timestamp, false otherwise.
 */
export function isPrior(to: number | string | Date) {
  return Date.now() < new Date(to).getTime();
}
